var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-width uk-animation-fade single"},[_c('Seo',{attrs:{"canonical":'/' + _vm.$t('berlinale2025').toLowerCase(),"title":_vm.$t('berlinale2025')}}),_c('div',{staticClass:"menu-headline-distance"}),_c('div',{staticClass:"uk-text-center"},[_c('img',{staticClass:"logo-img",attrs:{"id":"reg-success","src":require('../../assets/' +
                    _vm.getMode +
                    '/images/berlinale2025.png'),"uk-img":""}})]),_c('div',{staticClass:"uk-margin-large-top"}),(_vm.success)?_c('div',{staticClass:"uk-animation-fade"},[_c('SiteHeadline',{attrs:{"switch-headline":"","is-main":"","headline":"Dein Name steht nun auf der Gästeliste. In Kürze wirst du eine E-Mail mit einer Bestätigung erhalten. Wir freuen uns auf Dich.<br/>Dein Cancel Cancer Team","message":"Danke für Deine Anmeldung zum Berlinale Event 2025","has-button":""}}),_c('div',{staticClass:"headline-message-distance"}),_vm._m(0),(false)?_c('div',{staticClass:"uk-margin-medium-top uk-text-center"},[_c('Button',{attrs:{"has-action":"","action":_vm.addCal,"is-large":"","mode":"success"}},[_vm._v(" Zum Kalender hinzufügen ")])],1):_vm._e()],1):_c('div',[_c('SiteHeadline',{attrs:{"switch-headline":"","is-main":"","headline":"Die Anmeldung für die Gästeliste erfolgt über das unten stehende Anmeldeformular.<br/>Bitte beachte, dass ohne Anmeldung keine Teilnahme am Event möglich ist.","message":"Herzliche Einladung zum Berlinale Empfang 2025","has-button":""}}),_c('div',{staticClass:"headline-message-distance"}),_vm._m(1),_c('div',{staticClass:"uk-margin-large-top"}),_c('SiteHeadline',{attrs:{"headline":"Anmeldung für die Gästeliste"}}),_c('div',{staticClass:"headline-item-distance"}),_c('div',{staticClass:"uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single"},[_c('EventForm',{on:{"success":function($event){return _vm.setSuccess()}}})],1)],1),_c('div',{staticClass:"uk-margin-large-top"}),_vm._m(2),_c('div',{staticClass:"uk-margin-medium-top"}),_vm._m(3),_c('div',{staticClass:"last-button-distance"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-struct site-width single uk-text-center"},[_c('div',[_vm._v("Füge den Termin bequem zu deinem Kalender hinzu.")]),_c('div',{staticClass:"uk-margin-small-top"}),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Wann:")]),_vm._v(" 15.02.2025")]),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Uhrzeit:")]),_vm._v(" 17:00 Uhr bis 22:00 Uhr ")]),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Wo:")]),_vm._v(" Fabrik 23, Gerichtstraße 23, 13347 Berlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-struct site-width single uk-text-center"},[_c('div',{staticClass:"text-bold uk-margin-small-bottom"},[_vm._v(" Infos zum Event ")]),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Wann:")]),_vm._v(" 15.02.2025")]),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Uhrzeit:")]),_vm._v(" 17:00 Uhr bis 22:00 Uhr ")]),_c('div',[_c('span',{staticClass:"text-bold"},[_vm._v("Wo:")]),_vm._v(" Fabrik 23, Gerichtstraße 23, 13347 Berlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-text uk-text-center"},[_c('div',[_vm._v(" Falls Du Fragen oder Anregungen hast, melde Dich jederzeit gerne bei uns: ")]),_c('div',{staticClass:"text-bold"},[_vm._v("event@cancelcancer.de")]),_c('div',{staticClass:"text-bold"},[_vm._v("Dein Cancel Cancer Team")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-text-center"},[_c('span',{staticClass:"text-bold"},[_vm._v("Cancel Cancer e.V.")]),_c('br'),_vm._v(" Entenmoorweg 45"),_c('br'),_vm._v(" 27578 Bremerhaven"),_c('br'),_vm._v(" info@cancelcancer.de"),_c('br'),_vm._v(" www.cancelcancer.de"),_c('br')])
}]

export { render, staticRenderFns }