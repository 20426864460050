<template>
    <div>
        <span id="event-form" />
        <div uk-grid>
            <div class="uk-width-1-1">
                <div v-if="salutationErr" class="input-headline uk-form-danger">
                    Anrede ist ein Pflichtfeld.
                </div>
                <div v-else class="input-headline">Anrede</div>

                <div uk-grid>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-on:click="salutationErr = false"
                                v-model="salutation"
                                class="uk-radio"
                                name="contact-option"
                                type="radio"
                                value="mrs" />
                            <span class="preferred-item">Frau</span>
                        </label>
                    </div>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-on:click="salutationErr = false"
                                v-model="salutation"
                                class="uk-radio"
                                name="contact-option"
                                type="radio"
                                value="mr" />
                            <span class="preferred-item">Herr</span>
                        </label>
                    </div>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-on:click="salutationErr = false"
                                v-model="salutation"
                                class="uk-radio"
                                name="contact-option"
                                type="radio"
                                value="hello" />
                            <span class="preferred-item">Hallo</span>
                        </label>
                    </div>
                </div>
            </div>

            <div
                v-for="(inputField, key) in textFields"
                :key="inputField.name"
                :class="inputField.width">
                <TextArea
                    v-if="inputField.type === 'textarea'"
                    v-model="inputField.value"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    @setValid="setValid" />
                <TextField
                    v-else
                    v-model="inputField.value"
                    :autocomplete="inputField.autocomplete"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :hasPasswordMeter="inputField.hasPasswordMeter"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :onEnter="inputField.onEnter"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    :is-optional="inputField.optional"
                    @setValid="setValid" />
            </div>

            <div class="uk-width-1-1">
                <div class="sepa-title">Hast Du eine Begleitung?</div>

                <div class="uk-margin-top" />
                <SwitchButton
                    v-model="withPartner"
                    class="begleitung"
                    id="begleitung"
                    label="Mit Begleitung" />

                <div v-if="withPartner" class="uk-margin-top">
                    Deine Anmeldung wird mit Begleitung vermerkt.
                    <Icon
                        class="uk-form-success uk-margin-small-left"
                        icon="check" />
                </div>
            </div>
        </div>

        <div class="uk-grid" uk-grid>
            <div class="uk-width-1-1">
                <Checkbox
                    v-model="subscribe"
                    :value="subscribe"
                    label="Ich möchte nach dem Event Fotos erhalten und bin einverstanden, E-Mails zur Vereinsarbeit zu bekommen."
                    name="subscribe" />
            </div>
        </div>

        <div class="uk-margin-medium-top" />
        <div class="uk-text-center">
            <Button
                text="Anmelden"
                is-large
                is-min-width
                :is-loading="loading"
                has-action
                :action="contact"
                mode="success" />
        </div>

        <div v-if="error" class="uk-margin-medium-bottom" />
        <ErrorMessage :error="error" />
    </div>
</template>

<script>
import axios from 'axios';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import SwitchButton from '@/components/SwitchButton.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import Icon from '../../components/Icon.vue';
import UIkit from 'uikit';

export default {
    name: 'EventForm',
    components: {
        Icon,
        ErrorMessage,
        SwitchButton,
        Checkbox,
        TextArea,
        TextField,
        Button,
    },
    data() {
        return {
            trigger: 0,
            textFields: [
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'forename',
                    label: 'forename',
                    rules: 'required',
                    icon: 'user',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'surname',
                    label: 'surname',
                    rules: 'required',
                    icon: 'users',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    value: '',
                    valid: false,

                    name: 'emailAddress',
                    label: 'emailAddress',
                    rules: 'required|email',
                    icon: 'mail',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
            ],

            salutation: '',
            salutationErr: false,
            withPartner: false,
            subscribe: true,

            error: null,
            loading: false,
            success: false,
        };
    },

    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        contact() {
            this.trigger++;

            if (!this.salutation) {
                this.salutationErr = true;
                UIkit.scroll('#event-form', {offset: 100}).scrollTo(
                    '#event-form'
                );
                return;
            }
            this.salutationErr = false;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    UIkit.scroll('#event-form', {offset: 100}).scrollTo(
                        '#event-form'
                    );
                    return;
                }
            }

            this.loading = true;

            let http = axios.create();
            http.post(this.getBaseUrl + '/api/events/berlinale2025', {
                salutation: this.salutation,
                forename: this.textFields[0].value,
                surname: this.textFields[1].value,
                email: this.textFields[2].value,
                partner: this.withPartner,
                subscribe: this.subscribe,
            })
                .then(() => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('success', true);
                })
                .catch((e) => {
                    this.error = e;
                    this.success = false;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading {
    height: 50px !important;
}

.feedback-thanks {
    font-size: 18px;
    height: 50px;
}

.contribution {
    font-weight: 500;
    font-size: 48px;
    color: #fff !important;
}

.uk-card-success {
    background-color: #0d5746 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.hint-2 {
    margin-top: 20px;
    font-size: 14px;
}

.unified-modifiers {
    width: 110px;
}

.sepa-title {
    font-weight: 500;
    margin-top: 5px;
}

.begleitung {
    width: 200px;
}
</style>
