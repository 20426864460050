import camcoreDE from './camcore/de';
import camcoreEN from './camcore/en';

import filmpiratenDE from './filmpiraten/de';
import filmpiratenEN from './filmpiraten/de';

import buddyDE from './buddy/de';
import buddyEN from './buddy/de';

import cancelcancerDE from './cancelcancer/de';
import cancelcancerEN from './cancelcancer/de';


let de = camcoreDE;
let en = camcoreEN;

if (origin.includes("camcore")) {
    de = camcoreDE;
    en = camcoreEN;
}

if (origin.includes("filmpiraten")) {
    de = filmpiratenDE;
    en = filmpiratenEN;
}

if (origin.includes("buddy")) {
    de = buddyDE;
    en = buddyEN;
}

if (origin.includes("cancelcancer")) {
    de = cancelcancerDE;
    en = cancelcancerEN;
}

if (origin.includes("localhost")) {
      de = camcoreDE; en = camcoreEN;
    // de = filmpiratenDE; en = filmpiratenEN;
     de = cancelcancerDE; en = cancelcancerEN;
}

export default {
    de,
    en
};
