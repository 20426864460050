<template>
    <div class="site-width uk-animation-fade single">
        <Seo
            :canonical="'/' + $t('berlinale2025').toLowerCase()"
            :title="$t('berlinale2025')" />

        <div class="menu-headline-distance" />

        <div class="uk-text-center">
            <img
                id="reg-success"
                :src="
                    require('../../assets/' +
                        getMode +
                        '/images/berlinale2025.png')
                "
                class="logo-img"
                uk-img />
        </div>
        <div class="uk-margin-large-top" />

        <div class="uk-animation-fade" v-if="success">
            <SiteHeadline
                switch-headline
                is-main
                headline="Dein Name steht nun auf der Gästeliste. In Kürze wirst du eine E-Mail mit einer Bestätigung erhalten. Wir freuen uns auf Dich.<br/>Dein Cancel Cancer Team"
                message="Danke für Deine Anmeldung zum Berlinale Event 2025"
                has-button>
            </SiteHeadline>
            <div class="headline-message-distance" />

            <div class="data-struct site-width single uk-text-center">
                <div>Füge den Termin bequem zu deinem Kalender hinzu.</div>
                <div class="uk-margin-small-top" />

                <div><span class="text-bold">Wann:</span> 15.02.2025</div>
                <div>
                    <span class="text-bold">Uhrzeit:</span> 17:00 Uhr bis 22:00
                    Uhr
                </div>
                <div>
                    <span class="text-bold">Wo:</span> Fabrik 23, Gerichtstraße
                    23, 13347 Berlin
                </div>
            </div>

            <div v-if="false" class="uk-margin-medium-top uk-text-center">
                <Button has-action :action="addCal" is-large mode="success">
                    Zum Kalender hinzufügen
                </Button>
            </div>
        </div>
        <div v-else>
            <SiteHeadline
                switch-headline
                is-main
                headline="Die Anmeldung für die Gästeliste erfolgt über das unten stehende Anmeldeformular.<br/>Bitte beachte, dass ohne Anmeldung keine Teilnahme am Event möglich ist."
                message="Herzliche Einladung zum Berlinale Empfang 2025"
                has-button>
            </SiteHeadline>
            <div class="headline-message-distance" />

            <div class="data-struct site-width single uk-text-center">
                <div class="text-bold uk-margin-small-bottom">
                    Infos zum Event
                </div>

                <div><span class="text-bold">Wann:</span> 15.02.2025</div>
                <div>
                    <span class="text-bold">Uhrzeit:</span> 17:00 Uhr bis 22:00
                    Uhr
                </div>
                <div>
                    <span class="text-bold">Wo:</span> Fabrik 23, Gerichtstraße
                    23, 13347 Berlin
                </div>
            </div>

            <div class="uk-margin-large-top" />
            <SiteHeadline headline="Anmeldung für die Gästeliste"/>
            <div class="headline-item-distance"/>
            <div
                class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
                <EventForm @success="setSuccess()" />
            </div>
        </div>

        <div class="uk-margin-large-top" />
        <div class="bottom-text uk-text-center">
            <div>
                Falls Du Fragen oder Anregungen hast, melde Dich jederzeit gerne
                bei uns:
            </div>

            <div class="text-bold">event@cancelcancer.de</div>
            <div class="text-bold">Dein Cancel Cancer Team</div>
        </div>

        <div class="uk-margin-medium-top" />
        <div class="uk-text-center">
            <span class="text-bold">Cancel Cancer e.V.</span><br />
            Entenmoorweg 45<br />
            27578 Bremerhaven<br />
            info@cancelcancer.de<br />
            www.cancelcancer.de<br />
        </div>

        <div class="last-button-distance" />
    </div>
</template>

<script>
import Seo from '../../components/Seo.vue';
import SiteHeadline from '@/components/SiteHeadline.vue';
import EventForm from './EventForm.vue';
import Button from '../../components/Button.vue';
import UIkit from 'uikit';

export default {
    name: 'Berlinale2025',
    components: {
        Button,
        EventForm,
        SiteHeadline,
        Seo,
    },
    data() {
        return {
            success: false,
        };
    },
    created() {
        if (this.getMode !== 'cancelcancer') {
            this.routeTo('');
        }
    },
    methods: {
        setSuccess() {
            this.success = true;

            UIkit.scroll('#reg-success').scrollTo('#reg-success');
        },
        addCal() {
            const event = {
                uid: 'event@cancelcancer.de',
                dtstamp: new Date().toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z', // Current timestamp in UTC
                dtstart: '20250225T170000',
                dtend: '20250225T220000',
                summary: 'Cancel Cancer & Friends Berlinale 2025',
                location: 'Fabrik 23, Gerichtstraße 23, 13347 Berlin',
                description: ''
            };

            const timezone = `
BEGIN:VTIMEZONE
TZID:Europe/Berlin
X-LIC-LOCATION:Europe/Berlin
BEGIN:STANDARD
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
TZNAME:CET
DTSTART:19701025T030000
RRULE:FREQ=YEARLY;BYDAY=-1SU;BYMONTH=10
END:STANDARD
BEGIN:DAYLIGHT
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
TZNAME:CEST
DTSTART:19700329T020000
RRULE:FREQ=YEARLY;BYDAY=-1SU;BYMONTH=3
END:DAYLIGHT
END:VTIMEZONE
    `.trim();

            const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//CancelCancer//NONSGML Berlinale 2025//DE
CALSCALE:GREGORIAN
${timezone}
BEGIN:VEVENT
UID:${event.uid}
DTSTAMP:${event.dtstamp}
DTSTART;TZID=Europe/Berlin:${event.dtstart}
DTEND;TZID=Europe/Berlin:${event.dtend}
SUMMARY:${event.summary}
LOCATION:${event.location}
DESCRIPTION:${event.description}
END:VEVENT
END:VCALENDAR
    `.trim();

            const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'event.ics';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        },
    },
};
</script>

<style lang="scss" scoped>
.logo-img {
    max-height: 150px;
}

.text-bold {
    font-weight: 500;
}

.data-struct {
    font-size: 20px;
}

.bottom-text {
    font-size: 18px;
}
</style>
