<template>
    <div class="contact-formular">
        <Modal :id="modalId" title="Mitgliedschaft">
            {{ parsedSalutation }} {{ parsedForename }},<br/>
            schön, dass Du Teil unseres gemeinnützigen Vereins Cancel Cancer
            e.V. bist.<br/><br/>

            Gemeinsam möchten wir auf die Wichtigkeit der Kinderkrebsforschung
            aufmerksam machen, die noch in vielen Teilen in den Kinderschuhen
            steckt. Deshalb ist diese Forschung zum Großteil auf private Spenden
            angewiesen.

            <div class="uk-margin-medium-bottom"/>
            <Button
                text="Schließen"
                is-large
                is-max
                class="uk-modal-close"
                mode="default-alt"/>
        </Modal>

        <div class="" uk-grid>
            <div class="uk-width-1-1 uk-text-center uk-flex-center uk-flex">
                <div
                    class="uk-card-hover uk-card uk-card-success uk-width-1-1 uk-padding-small">
                    <div class="contribution">
                        {{ getNumberWithCurrency(contribution) }}
                    </div>

                    <div class="hint">jährlich</div>

                    <div class="uk-margin-medium-top"/>
                    <div
                        class="uk-grid-small uk-child-width-auto uk-flex-center uk-grid-small"
                        uk-grid>
                        <div
                            class=""
                            v-for="modifier in modifiers"
                            :key="modifier"
                            v-on:click="modify(modifier)">
                            <Button mode="default-alt" is-small>
                                + {{ getNumberWithCurrency(modifier) }}
                            </Button>
                        </div>
                    </div>

                    <div
                        class="uk-grid-small uk-child-width-auto uk-flex-center uk-grid-small"
                        uk-grid>
                        <div
                            class=""
                            v-for="modifier in modifiers"
                            :key="modifier"
                            v-on:click="modify(modifier * -1)">
                            <Button mode="default-alt" is-small>
                                - {{ getNumberWithCurrency(modifier) }}
                            </Button>
                        </div>
                    </div>

                    <div class="hint-2">
                        Der Jahres-Beitrag beträgt mind. {{ getNumberWithCurrency(minContribution) }} und ist von der
                        Steuer absetzbar.
                    </div>
                </div>
            </div>

            <div class="uk-width-1-1">
                <div class="input-headline">Anmeldung als</div>

                <div uk-grid>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-on:change="changeRegistrationAs()"
                                v-model="registrationAs"
                                class="uk-radio"
                                name="register-option"
                                type="radio"
                                value="person"/>
                            <span class="preferred-item">Person</span>
                        </label>
                    </div>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-on:change="changeRegistrationAs()"
                                v-model="registrationAs"
                                class="uk-radio"
                                name="register-option"
                                type="radio"
                                value="company"/>
                            <span class="preferred-item">Firma</span>
                        </label>
                    </div>
                </div>

                <div v-if="registrationAs === 'company'" class="uk-animation-fade uk-grid-small" uk-grid>
                    <div
                        v-for="(inputField, key) in companyFields"
                        :key="inputField.name"
                        :class="inputField.width">
                        <TextArea
                            v-if="inputField.type === 'textarea'"
                            v-model="inputField.value"
                            :autofocus="inputField.autofocus"
                            :field-key="key"
                            :icon="inputField.icon"
                            :label="inputField.label"
                            :name="inputField.name"
                            :required="inputField.required"
                            :rules="inputField.rules"
                            :type="inputField.type"
                            :validate="trigger"
                            @setValid="setValidCompany"/>
                        <TextField
                            v-else
                            v-model="inputField.value"
                            :autocomplete="inputField.autocomplete"
                            :autofocus="inputField.autofocus"
                            :field-key="key"
                            :hasPasswordMeter="inputField.hasPasswordMeter"
                            :icon="inputField.icon"
                            :label="inputField.label"
                            :name="inputField.name"
                            :onEnter="inputField.onEnter"
                            :required="inputField.required"
                            :rules="inputField.rules"
                            :type="inputField.type"
                            :validate="trigger"
                            @setValid="setValidCompany"/>
                    </div>

                    <div class="uk-width-1-1">
                        Der Jahres-Beitrag als Firma beträgt mind. 250,00 € und ist von der Steuer absetzbar.
                    </div>
                </div>
            </div>

            <div class="uk-width-1-1">
                <div class="input-headline">Anrede</div>

                <div uk-grid>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-model="preferred"
                                class="uk-radio"
                                name="contact-option"
                                type="radio"
                                value="mrs"/>
                            <span class="preferred-item">Frau</span>
                        </label>
                    </div>
                    <div>
                        <label class="cursor-pointer">
                            <input
                                v-model="preferred"
                                class="uk-radio"
                                name="contact-option"
                                type="radio"
                                value="mr"/>
                            <span class="preferred-item">Herr</span>
                        </label>
                    </div>
                </div>
            </div>

            <div
                v-for="(inputField, key) in textFields"
                :key="inputField.name"
                :class="inputField.width">
                <TextArea
                    v-if="inputField.type === 'textarea'"
                    v-model="inputField.value"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    @setValid="setValid"/>
                <TextField
                    v-else
                    v-model="inputField.value"
                    :autocomplete="inputField.autocomplete"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :hasPasswordMeter="inputField.hasPasswordMeter"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :onEnter="inputField.onEnter"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    :is-optional="inputField.optional"
                    @setValid="setValid"/>
            </div>

            <div class="uk-width-1-1">
                <div class="sepa-title">SEPA-Lastschrift</div>

                <br/>

                <div
                    v-for="(inputField, key) in ibanFields"
                    :key="inputField.name"
                    :class="inputField.width">
                    <TextArea
                        v-if="inputField.type === 'textarea'"
                        v-model="inputField.value"
                        :autofocus="inputField.autofocus"
                        :field-key="key"
                        :icon="inputField.icon"
                        :label="inputField.label"
                        :name="inputField.name"
                        :required="inputField.required"
                        :rules="inputField.rules"
                        :type="inputField.type"
                        :validate="trigger"
                        @setValid="setValidIban"/>
                    <TextField
                        v-else
                        v-model="inputField.value"
                        :autocomplete="inputField.autocomplete"
                        :autofocus="inputField.autofocus"
                        :field-key="key"
                        :hasPasswordMeter="inputField.hasPasswordMeter"
                        :icon="inputField.icon"
                        :label="inputField.label"
                        :name="inputField.name"
                        :onEnter="inputField.onEnter"
                        :required="inputField.required"
                        :rules="inputField.rules"
                        :type="inputField.type"
                        :validate="trigger"
                        @setValid="setValidIban"/>
                </div>

                <div class="uk-margin-top"/>
                <SwitchButton
                    v-model="ibanName"
                    class="uk-width-medium"
                    id="iban"
                    label="Abweichender Kontoinhaber"/>

                <div v-if="ibanName" class="uk-animation-fade" uk-grid>
                    <div
                        v-for="(inputField, key) in ibanNameFields"
                        :key="inputField.name"
                        :class="inputField.width">
                        <TextArea
                            v-if="inputField.type === 'textarea'"
                            v-model="inputField.value"
                            :autofocus="inputField.autofocus"
                            :field-key="key"
                            :icon="inputField.icon"
                            :label="inputField.label"
                            :name="inputField.name"
                            :required="inputField.required"
                            :rules="inputField.rules"
                            :type="inputField.type"
                            :validate="trigger"
                            @setValid="setValidIbanName"/>
                        <TextField
                            v-else
                            v-model="inputField.value"
                            :autocomplete="inputField.autocomplete"
                            :autofocus="inputField.autofocus"
                            :field-key="key"
                            :hasPasswordMeter="inputField.hasPasswordMeter"
                            :icon="inputField.icon"
                            :label="inputField.label"
                            :name="inputField.name"
                            :onEnter="inputField.onEnter"
                            :required="inputField.required"
                            :rules="inputField.rules"
                            :type="inputField.type"
                            :validate="trigger"
                            @setValid="setValidIbanName"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-grid" uk-grid>
            <div class="uk-width-1-1">
                <Checkbox
                    v-model="confirmSepa"
                    :value="confirmSepa"
                    label="sepa"
                    :validate="trigger"
                    name="sepa"
                    rules="required"
                    @setValid="setSepaValid"/>

                <Checkbox
                    v-model="confirmTerms"
                    :value="confirmTerms"
                    :validate="trigger"
                    label="acceptDataPrivacyMessage"
                    name="terms"
                    rules="required"
                    @setValid="setTermsValid"/>
            </div>
        </div>

        <div class="uk-margin-medium-top"/>
        <div class="uk-text-center">
            <Button
                text="Kostenpflichtig Mitglied werden"
                is-large
                is-min-width
                :is-loading="loading"
                has-action
                :action="contact"
                mode="success"/>

            <div class="uk-margin-small-top">
                {{ getNumberWithCurrency(contribution) }} Jahres-Beitrag
            </div>
        </div>

        <div v-if="error" class="uk-margin-medium-bottom"/>
        <ErrorMessage :error="error"/>
    </div>
</template>

<script>
import {Validator} from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber';
import axios from 'axios';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import UIkit from 'uikit';
import SwitchButton from '@/components/SwitchButton.vue';
import Modal from '@/components/Modal.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
    name: 'MembershipFormular',
    components: {
        ErrorMessage,
        Modal,
        SwitchButton,
        Checkbox,
        TextArea,
        TextField,
        Button,
    },
    props: {
        selected: Boolean,
        name: String,
        icon: String,
    },
    data() {
        return {
            feedback: null,
            loadingFeedback: false,
            requestStatusFeedback: null,

            acquisitionOptions: [
                {name: 'recommendation', icon: 'users'},
                {name: 'socialMedia', icon: 'hashtag'},
                {name: 'searchEngine', icon: 'search'},
            ],

            trigger: 0,
            textFields: [
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'forename',
                    label: 'forename',
                    rules: 'required',
                    icon: 'user',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'surname',
                    label: 'surname',
                    rules: 'required',
                    icon: 'hashtag',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'street',
                    label: 'street',
                    rules: 'required',
                    icon: 'home',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'streetNumber',
                    label: 'streetNumber',
                    rules: 'required',
                    icon: 'tag',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'zip',
                    label: 'zip',
                    icon: 'location',
                    rules: 'required|numeric|max:320',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'city',
                    label: 'city',
                    icon: 'world',
                    rules: 'required|max:320',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    value: '',
                    valid: false,

                    name: 'emailAddress',
                    label: 'emailAddress',
                    rules: 'required|email',
                    icon: 'mail',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    value: '',
                    valid: true,

                    name: 'telephone',
                    label: 'telephone',
                    rules: 'min:6',
                    icon: 'receiver',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    optional: true,
                },

                {
                    value: '',
                    valid: true,

                    name: 'profession',
                    label: 'profession',
                    rules: '',
                    icon: 'file-text',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    optional: true,
                },
            ],

            minContribution: 15.0,
            contribution: 15.0,

            modalId: 'modal-membership-success',

            modifiers: [1, 5, 10, 50, 100],

            parsedSalutation: '',
            parsedForename: '',

            ibanFields: [
                {
                    value: '',
                    valid: false,

                    name: 'iban',
                    label: 'iban',
                    rules: 'required',
                    icon: 'credit-card',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
            ],

            ibanName: false,

            ibanNameFields: [
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'forename',
                    label: 'forenameIban',
                    rules: 'required',
                    icon: 'user',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'surname',
                    label: 'surnameIban',
                    rules: 'required',
                    icon: 'users',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'street',
                    label: 'streetIban',
                    rules: 'required',
                    icon: 'home',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'streetNumber',
                    label: 'streetNumberIban',
                    rules: 'required',
                    icon: 'tag',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },

                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'zip',
                    label: 'zipIban',
                    icon: 'location',
                    rules: 'required|numeric|max:320',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'city',
                    label: 'cityIban',
                    icon: 'world',
                    rules: 'required|max:320',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
            ],

            ibanDiff: false,

            companyFields: [
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'company',
                    label: 'company',
                    rules: 'required',
                    icon: 'cog',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                },
            ],

            registrationAs: 'person',
            preferred: 'mrs',
            privacy: false,
            sepa: false,
            confirmTerms: false,
            confirmSepa: false,

            loading: null,
            error: null,
            requestStatus: null,
            acquisitionUuid: 0,
        };
    },
    created() {
        let self = this;
        Validator.extend('phone', {
            message(fieldName) {
                return `${fieldName} is not a valid phone number`;
            },
            validate(value) {
                return new Promise((resolve) => {
                    let phone = new PhoneNumber(value, self.$i18n.locale);
                    resolve({valid: phone.isValid()});
                });
            },
        });
    },
    computed: {
        isTelValid() {
            let telValue = this.textFields[3].value;

            if (telValue.length <= 0) {
                this.resetPreferred();
                return false;
            }

            if (this.errors.first('telephone')) {
                this.resetPreferred();
                return false;
            }

            return true;
        },
    },
    methods: {
        changeRegistrationAs() {
            if (this.registrationAs === 'company') {
                this.minContribution = 250.0;
                this.contribution = 250.0;
                return;
            }

            this.minContribution = 15.0;
            this.contribution = 15.0;
        },
        modify(modifier) {
            let result = this.contribution;

            result += modifier;
            if (result < this.minContribution) {
                this.contribution = this.minContribution;
                return;
            }

            this.contribution = result;
        },
        getNumberWithCurrency(number) {
            return new Intl.NumberFormat('de', {
                style: 'currency',
                currency: 'EUR',
            }).format(number);
        },
        reload() {
            location.reload();
        },
        setTermsValid(valid) {
            this.privacy = valid;
        },
        setSepaValid(valid) {
            this.sepa = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        setValidIban(valid, fieldKey) {
            this.ibanFields[fieldKey].valid = valid;
        },
        setValidIbanName(valid, fieldKey) {
            this.ibanNameFields[fieldKey].valid = valid;
        },
        setValidCompany(valid, fieldKey) {
            this.companyFields[fieldKey].valid = valid;
        },
        prepareForNewMessage() {
            /*
            for (let i = 0, j = this.textFields.length; i < j; i++) {
                this.textFields[i].value = "";
            }
             */

            // this.textFields[0].valid = false;
            // this.textFields[2].valid = false;

            this.textFields[4].value = '';
            this.textFields[4].valid = false;

            this.preferred = 'email';
            // this.confirmTerms = false;
            // this.privacy = false;
            this.requestStatus = null;
            this.acquisitionUuid = 0;
            this.requestStatusFeedback = null;
            this.feedback = null;

            UIkit.scroll('#contact-form', {offset: 30}).scrollTo(
                '#contact-form'
            );
        },
        acquisition() {
            this.loadingFeedback = true;
            this.requestStatusFeedback = null;

            let http = axios.create();
            http.post(this.getBaseUrl + '/api/acquisitions', {
                uuid: this.acquisitionUuid,
                feedback: this.feedback,
            })
                .then((response) => {
                    this.requestStatusFeedback = response.status;
                    this.loadingFeedback = false;
                })
                .catch((e) => {
                    this.requestStatusFeedback = 500;
                    if (e && e.response) {
                        this.requestStatusFeedback = e.response.status;
                    }

                    this.loadingFeedback = false;
                });
        },
        resetPreferred() {
            this.preferred = 'email';
        },
        resetInputs() {
            for (let i = 0, j = this.textFields.length; i < j; i++) {
                this.textFields[i].value = '';
                this.textFields[i].valid = false;
            }

            this.textFields[7].valid = false;
            this.textFields[8].valid = false;

            for (let i = 0, j = this.ibanFields.length; i < j; i++) {
                this.ibanFields[i].value = '';
                this.ibanFields[i].valid = false;
            }

            for (let i = 0, j = this.ibanNameFields.length; i < j; i++) {
                this.ibanNameFields[i].value = '';
                this.ibanNameFields[i].valid = false;
            }

            for (let i = 0, j = this.companyFields.length; i < j; i++) {
                this.companyFields[i].value = '';
                this.companyFields[i].valid = false;
            }

            this.ibanName = false;

            this.registrationAs = 'person';

            this.minContribution = 15.0;
            this.contribution = 15.0;
            this.confirmTerms = false;
            this.confirmSepa = false;
            this.sepa = false;
            this.privacy = false;
            this.preferred = 'mrs';
        },
        contact() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.ibanFields.length; i < j; i++) {
                if (!this.ibanFields[i].valid) {
                    return;
                }
            }

            if (this.ibanName) {
                for (let i = 0, j = this.ibanNameFields.length; i < j; i++) {
                    if (!this.ibanNameFields[i].valid) {
                        return;
                    }
                }
            }

            if (this.registrationAs === 'company') {
                for (let i = 0, j = this.companyFields.length; i < j; i++) {
                    if (!this.companyFields[i].valid) {
                        return;
                    }
                }
            }

            if (!this.sepa) {
                return;
            }

            if (!this.privacy) {
                return;
            }

            this.loading = true;
            this.requestStatus = null;

            let http = axios.create();

            http.post(this.getBaseUrl + '/api/memberships', {
                contribution: this.contribution,

                company: this.companyFields[0].value,
                salutation: this.preferred,
                forename: this.textFields[0].value,
                surname: this.textFields[1].value,
                street: this.textFields[2].value,
                street_number: this.textFields[3].value,
                zip: this.textFields[4].value,
                city: this.textFields[5].value,

                email: this.textFields[6].value,
                telephone: this.textFields[7].value,
                profession: this.textFields[8].value,

                iban: this.ibanFields[0].value,

                iban_forename: this.ibanNameFields[0].value,
                iban_surname: this.ibanNameFields[1].value,
                iban_street: this.ibanNameFields[2].value,
                iban_street_number: this.ibanNameFields[3].value,
                iban_zip: this.ibanNameFields[4].value,
                iban_city: this.ibanNameFields[5].value,
            })
                .then((response) => {
                    let salutation = 'Liebe';
                    if (this.preferred === 'mr') {
                        salutation = 'Lieber';
                    }
                    this.parsedSalutation = salutation;
                    this.parsedForename = this.textFields[0].value;

                    this.requestStatus = response.status;
                    this.acquisitionUuid = response.data.acquisition_code;
                    this.loading = false;
                    this.error = null;

                    this.resetInputs();

                    UIkit.modal('#' + this.modalId).show();
                })
                .catch((e) => {
                    this.error = e;

                    this.requestStatus = 500;
                    if (e && e.response) {
                        this.requestStatus = e.response.status;
                    }

                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading {
    height: 50px !important;
}

.feedback-thanks {
    font-size: 18px;
    height: 50px;
}

.uk-radio:checked {
}

.hint {
}

.contribution {
    font-weight: 500;
    font-size: 48px;
    color: #fff !important;
}

.uk-card-success {
    background-color: #0d5746 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.hint-2 {
    margin-top: 20px;
    font-size: 14px;
}

.unified-modifiers {
    width: 110px;
}

.sepa-title {
    font-weight: 500;
    margin-top: 5px;
}
</style>
