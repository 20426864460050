<template>
    <div class="site-width uk-animation-fade single">
        <Seo
            :canonical="'/' + $t('donate').toLowerCase()"
            :title="$t('donate')" />

        <div class="menu-headline-distance" />
        <SiteHeadline
            is-main
            :message="$t('donate.message')"
            :headline="$t('donate')"
            has-button>
            <div class="headline-message-distance" />

            <FeatureItem
                class="uk-text-left"
                message="Setz dich in CC und beweg was.
            Gemeinsam Gutes tun. Die Spendengelder werden eingesetzt, um die Krebsforschung, insbesondere die Kinderkrebsforschung, ideell und materiell zu unterstützen.
            Mit seiner Öffentlichkeitsarbeit, seinen Filmproduktionen und Veranstaltungen will der Verein die Allgemeinheit für das Thema Krebs sensibilisieren. Wir wollen über die Herausforderungen, Bedürfnisse und Nöte von Menschen mit Krebserkrankungen berichten, um Krankenhäusern, Hospizen, Einrichtungen, betroffene Personen und Familien eine Stimme zu geben." />
        </SiteHeadline>

        <div class="headline-message-distance" />

        <div v-if="false">
        <span id="paypal" />
        <div class="section-distance" />
        <SiteHeadline headline="Paypal" message="Spendenlink" />
        <div class="headline-item-distance" />
        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">
            <a
                href="https://www.paypal.com/donate/?hosted_button_id=SZZVSE8W73FQG"
                target="_blank">
                <Button is-large is-min-width mode="success">
                    Zu Paypal
                </Button>
            </a>
        </div>
        </div>

        <div class="section-distance" />
        <span id="überweisung" />
        <span id="ueberweisung" />
        <div class="section-distance" />
        <Wire />

        <div class="last-button-distance" />
    </div>
</template>

<script>
import Seo from '../../components/Seo.vue';
import Wire from '@/components/Wire.vue';
import FeatureItem from '@/components/FeatureItem.vue';
import SiteHeadline from '@/components/SiteHeadline.vue';
import Button from '@/components/Button.vue';
import UIkit from 'uikit';

export default {
    name: 'Donate',
    components: {
        Button,
        SiteHeadline,
        FeatureItem,
        Wire,
        Seo,
    },
    created() {
        if (this.getMode !== 'cancelcancer') {
            this.routeTo('');
        }
    },
    mounted() {
        let hashtag = location.hash;
        console.log(hashtag);
        if (hashtag && ['#paypal', '#ueberweisung'].includes(hashtag)) {
            setTimeout(function () {
                UIkit.scroll(hashtag, {offset: 90}).scrollTo(hashtag);
            }, 50);
        }
    },
};
</script>

<style lang="scss" scoped></style>
